import Vue from "vue";
import Vuex from "vuex";
import app from "./app";
import home from "./home";
import user from "./user";
import product from "./product";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    home,
    user,
    product,
  },
});

import { HOST } from "@/constant";
import request from "@/utils/request";

const member = `${HOST.ADMIN_API_URL}/Member`;

export const GetMemberList = (data) => {
  return request({
    url: `${member}/GetMemberList`,
    method: "GET",
    data,
  });
};
export const GetMemberAuditList = (data) => {
  return request({
    url: `${member}/GetMemberAuditList`,
    method: "GET",
    data,
  });
};
export const GetMemberByCode = (data) => {
  return request({
    url: `${member}/GetMemberByCode`,
    method: "GET",
    data,
  });
};
export const GetMemberByMobile = (data) => {
  return request({
    url: `${member}/GetMemberByMobile`,
    method: "GET",
    data,
  });
};
export const SaveMember = (data) => {
  return request({
    url: `${member}/SaveMember`,
    method: "POST",
    data,
  });
};
export const AuditMember = (data) => {
  return request({
    url: `${member}/AuditMember`,
    method: "POST",
    data,
  });
};

import { HOST } from "@/constant";
import request from "@/utils/request";

const home = `${HOST.ADMIN_API_URL}/Home`;
const log = `${HOST.LOG_API_URL}`;

export const GetIndexData = (data) => {
  return request({
    url: `${home}/GetIndexData`,
    method: "GET",
    data,
  });
};
export const GetBonusStat = () => {
  return request({
    url: `${home}/GetBonusStat`,
    method: "GET",
  });
};
export const GetMemberStat = () => {
  return request({
    url: `${home}/GetMemberStat`,
    method: "GET",
  });
};

export const getProductSellsRank = (data) =>
  request({
    url: `${home}/GetProductSellsRank`,
    method: "GET",
    data,
  });

export const getMemberShoppingRank = (data) =>
  request({
    url: `${home}/GetMemberShoppingRank`,
    method: "GET",
    data,
  });
export const GetCount = () => {
  return request({
    url: `${home}/GetCount`,
    method: "GET",
  });
};
export const GetLoginLogByLoginName = (data) => {
  return request({
    url: `${log}/GetLoginLogByLoginName`,
    method: "GET",
    data,
  });
};
export const GetAddressList = (data) => {
  return request({
    // url: `${window.location.origin}/jsonfiles/china_address_v7.json`,
    url: "https://ssdms.cn/res/SIX/jsonfiles/china_address_v7.json",
    method: "GET",
    data,
  });
};

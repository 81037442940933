export const token = "gpos-token";
export const storageName = {
  userInfo: "userInfo",
  userModuleList: "userModuleList",
  useCaseList: "useCaseList",
  tagNavList: "tagNavList",
};
export const setStoreage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};
export const getStorage = (key) => {
  return JSON.parse(localStorage.getItem(key));
};

import actions from "./actions";
import getters from "./getters";
import { state, mutations } from "./mutations";

export default {
  state,
  actions,
  getters,
  mutations,
};

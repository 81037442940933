<template>
  <div class="side-menu-wrapper">
    <slot />
    <Menu
      ref="menu"
      v-show="!collapsed"
      :active-name="activeName"
      :open-names="openedNames"
      :accordion="accordion"
      :theme="theme"
      width="auto"
      @on-select="handleSelect"
    >
      <template v-for="item in menuList">
        <template v-if="item.children && item.children.length === 1">
          <side-menu-item
            v-if="showChildren(item)"
            :key="`menu-${item.name}`"
            :parent-item="item"
          />
          <menu-item
            v-else
            :name="getNameOrHref(item, true)"
            :key="`menu-${item.children[0].name}`"
          >
            <Icon color="#fff" :type="item.icon || ''" />
            <span>{{ showTitle(item.children[0]) }}</span>
          </menu-item>
        </template>
        <template v-else>
          <side-menu-item
            v-if="showChildren(item)"
            :key="`menu-${item.name}`"
            :parent-item="item"
          />
          <menu-item
            v-else
            :name="getNameOrHref(item)"
            :key="`menu1-${item.name}`"
          >
            <Icon :type="item.icon || ''" />
            <span>{{ showTitle(item) }}</span>
          </menu-item>
        </template>
      </template>
    </Menu>

    <div class="menu-collapsed" v-show="collapsed" :list="menuList">
      <template v-for="item in menuList">
        <collapsed-menu
          v-if="item.children && item.children.length > 1"
          @on-click="handleSelect"
          hide-title
          :root-icon-size="rootIconSize"
          :icon-size="iconSize"
          :theme="theme"
          :parent-item="item"
          :key="`drop-menu-${item.name}`"
        />
        <Tooltip
          transfer
          v-else
          :content="
            showTitle(
              item.children && item.children[0] ? item.children[0] : item
            )
          "
          placement="right"
          :key="`drop-menu1-${item.name}`"
        >
          <a
            @click="onSelect(getNameOrHref(item, true))"
            class="drop-menu-a"
            :style="{ textAlign: 'center' }"
          >
            <Icon
              :size="rootIconSize"
              color="#FFFFFF"
              :type="item.icon || (item.children && item.children[0].icon)"
          /></a>
        </Tooltip>
      </template>
    </div>
  </div>
</template>

<script>
import SideMenuItem from "./side-menu-item.vue";
import CollapsedMenu from "./collapsed-menu.vue";
import { getUnion } from "@/utils";
import mixin from "./mixin";
import { HomeName } from "@/constant";

export default {
  name: "SideMenu",
  components: { SideMenuItem, CollapsedMenu },
  mixins: [mixin],
  props: {
    menuList: {
      type: Array,
      default() {
        return [];
      },
    },
    collapsed: Boolean,
    theme: { type: String, default: "dark" },
    rootIconSize: { type: Number, default: 20 },
    iconSize: { type: Number, default: 16 },
    accordion: Boolean,
    activeName: { type: String, default: "" },
    openNames: { type: Array, default: () => {} },
  },
  data() {
    return {
      openedNames: [],
    };
  },
  methods: {
    handleSelect(name) {
      this.$emit("on-select", name);
    },
    getOpenedNamesByActiveName(name) {
      return this.$route.matched
        .map((item) => item.name)
        .filter((item) => item !== name);
    },
    updateOpenName(name) {
      if (name === HomeName) this.openedNames = [];
      else this.openedNames = this.getOpenedNamesByActiveName(name);
    },
  },
  watch: {
    activeName(name) {
      if (this.accordion)
        this.openedNames = this.getOpenedNamesByActiveName(name);
      else
        this.openedNames = getUnion(
          this.openedNames,
          this.getOpenedNamesByActiveName(name)
        );
    },
    openNames(newNames) {
      this.openedNames = newNames;
    },
    openedNames() {
      this.$nextTick(() => {
        this.$refs.menu.updateOpened();
      });
    },
  },
  mounted() {
    this.openedNames = getUnion(
      this.openedNames,
      this.getOpenedNamesByActiveName(name)
    );
  },
};
</script>

import { HOST } from "@/constant";
import request from "@/utils/request";

const config = `${HOST.ADMIN_API_URL}/Config`;

export const EditMemberLevel = (data) => {
  return request({
    url: `${config}/EditMemberLevel`,
    method: "POST",
    data,
  });
};
export const GetAllMemberLevel = () => {
  return request({
    url: `${config}/GetAllMemberLevel`,
    method: "GET",
  });
};
export const SaveExpress = (data) => {
  return request({
    url: `${config}/SaveExpress`,
    method: "POST",
    data,
  });
};
export const GetExpressList = (data) => {
  return request({
    url: `${config}/GetExpressList`,
    method: "GET",
    data,
  });
};
export const SavePayConfig = (data) => {
  return request({
    url: `${config}/SavePayConfig`,
    method: "POST",
    data,
  });
};
export const GetPayConfigList = (data) => {
  return request({
    url: `${config}/GetPayConfigList`,
    method: "GET",
    data,
  });
};
export const SaveBank = (data) => {
  return request({
    url: `${config}/SaveBank`,
    method: "POST",
    data,
  });
};
export const DeleteBank = (data) => {
  return request({
    url: `${config}/DeleteBank`,
    method: "POST",
    data,
  });
};
export const GetBankList = (data) => {
  return request({
    url: `${config}/GetBankList`,
    method: "GET",
    data,
  });
};
export const GetSysConfigList = (data) => {
  return request({
    url: `${config}/GetSysConfigList`,
    method: "GET",
    data,
  });
};
export const DeleteSysConfig = (data) => {
  return request({
    url: `${config}/DeleteSysConfig`,
    method: "POST",
    data,
  });
};
export const SaveSysConfig = (data) => {
  return request({
    url: `${config}/SaveSysConfig`,
    method: "POST",
    data,
  });
};
export const GetSysConfigByKey = (data) => {
  return request({
    url: `${config}/GetSysConfigByKey`,
    method: "GET",
    data,
  });
};
export const GetAdvertList = (data) => {
  return request({
    url: `${config}/GetAdvertList`,
    method: "GET",
    data,
  });
};
export const SaveAdvert = (data) => {
  return request({
    url: `${config}/SaveAdvert`,
    method: "POST",
    data,
  });
};
export const SaveAdvertIndexNum = (data) => {
  return request({
    url: `${config}/SaveAdvertIndexNum`,
    method: "POST",
    data,
  });
};
export const DeleteAdvert = (data) => {
  return request({
    url: `${config}/DeleteAdvert`,
    method: "POST",
    data,
  });
};
export const GetAdvertPositionList = (data) => {
  return request({
    url: `${config}/GetAdvertPositionList`,
    method: "GET",
    data,
  });
};
export const SaveAdvertPosition = (data) => {
  return request({
    url: `${config}/SaveAdvertPosition`,
    method: "POST",
    data,
  });
};
export const DeleteAdvertPosition = (data) => {
  return request({
    url: `${config}/DeleteAdvertPosition`,
    method: "POST",
    data,
  });
};
export const GetAllAdvertPosition = (data) => {
  return request({
    url: `${config}/GetAllAdvertPosition`,
    method: "GET",
    data,
  });
};

import { HOST } from "@/constant";
import request from "@/utils/request";

const report = `${HOST.ADMIN_API_URL}/Report`;

export const GetYearPayAmount = (data) => {
  return request({
    url: `${report}/GetYearPayAmount`,
    method: "GET",
    data,
  });
};

export const GetDateYearPayAmount = (data) => {
  return request({
    url: `${report}/GetDateYearPayAmount`,
    method: "GET",
    data,
  });
};

export const ExportYearPayAmount = (data) => {
  return request({
    url: `${report}/ExportYearPayAmount`,
    method: "POST",
    responseType: "arraybuffer",
    data,
  });
};

export const ExportDateYearPayAmount = (data) => {
  return request({
    url: `${report}/ExportDateYearPayAmount`,
    method: "POST",
    responseType: "arraybuffer",
    data,
  });
};

import { HomeName } from "@/constant";
import Main from "@/layout/index.vue";

const _import = (path) => () => import(`@/views/${path}`);
const routes = [
  // 首页
  {
    path: "/",
    name: "Layout",
    redirect: "/Home",
    component: Main,
    meta: {
      hideInMenu: true,
      noCache: true,
    },
    children: [
      {
        name: HomeName,
        path: "/Home",
        component: _import("home/index.vue"),
        meta: {
          hideInMenu: true,
          title: "首页",
          noCache: true,
          icon: "home-fill",
        },
      },
    ],
  },
  /** 登录 */
  {
    path: "/Login",
    name: "Login",
    meta: { title: "登录", hideInMenu: true },
    component: _import("login/index.vue"),
  },
  /** 会员 */
  {
    name: "Member",
    path: "/Member",
    component: Main,
    meta: {
      icon: "md-person",
      title: "会员管理",
      access: ["MemberManage"],
      showAlway: true,
    },
    children: [
      {
        name: "MemberList",
        path: "MemberList",
        component: _import("member/member.vue"),
        meta: {
          title: "会员列表",
          acccess: ["MemberList"],
          useCase: ["Member_Search", "Member_Edit"],
        },
      },
      {
        name: "MemberAudit",
        path: "MemberAudit",
        component: _import("member/member-audit.vue"),
        meta: {
          title: "会员审核",
          access: ["MemberAudit"],
          useCase: ["Member_Audit"],
        },
      },
    ],
  },
  /* 商品管理 */
  {
    name: "Product",
    path: "/Product",
    component: Main,
    meta: {
      title: "商品管理",
      icon: "md-appstore",
      access: ["ProductManage"],
      showAlways: true,
    },
    children: [
      {
        name: "ProductBrand",
        path: "ProductBrand",
        component: _import("product/brand.vue"),
        meta: {
          title: "品牌管理",
          access: ["ProductBrand"],
          useCase: [
            "ProductBrand_Search",
            "ProductBrand_Edit",
            "ProductBrand_Delete",
          ],
        },
      },
      {
        name: "ProductClass",
        path: "ProductClass",
        component: _import("product/class.vue"),
        meta: {
          title: "分类管理",
          access: ["ProductClass"],
          useCase: ["ProductClass_Edit", "ProductClass_Delete"],
        },
      },
      {
        name: "ProductManage",
        path: "ProductManage",
        component: _import("product/product.vue"),
        meta: {
          title: "商品管理",
          access: ["ProductManage"],
          useCase: ["Product_Search", "Product_Edit", "Product_Edit"],
        },
      },
      {
        name: "ProductStock",
        path: "ProductStock",
        component: _import("product/product-stock.vue"),
        meta: {
          title: "商品库存",
          access: ["ProductStock"],
          useCase: ["ProductStock_Search"],
        },
      },
    ],
  },
  // 订单管理
  {
    name: "Order",
    path: "/Order",
    component: Main,
    meta: {
      title: "订单管理",
      icon: "ios-keypad",
      access: ["OrderManage"],
      showAlways: true,
    },
    children: [
      {
        name: "AllOrder",
        path: "AllOrder",
        component: _import("order/order.vue"),
        meta: {
          title: "所有订单",
          access: ["AllOrder"],
          useCase: ["Order_Search", "Order_Edit"],
        },
      },
      {
        name: "WaitShipOrder",
        path: "WaitShipOrder",
        component: _import("order/wait-ship-order.vue"),
        meta: {
          title: "待发货",
          access: ["WaitShipOrder"],
          useCase: ["Order_Search", "Order_SendEms"],
        },
      },
      {
        name: "ShippedOrder",
        path: "ShippedOrder",
        component: _import("order/shipped-order.vue"),
        meta: {
          title: "待收货",
          access: ["ShippedOrder"],
          useCase: ["Order_Search"],
        },
      },
      {
        name: "FinlishedOrder",
        path: "FinlishedOrder",
        component: _import("order/finished-order.vue"),
        meta: {
          title: "已完成",
          access: ["FinlishedOrder"],
          useCase: ["Order_Search"],
        },
      },
      {
        name: "WaitRefundOrder",
        path: "WaitRefundOrder",
        component: _import("order/wait-refund-order.vue"),
        meta: {
          title: "待退款",
          access: ["WaitRefundOrder"],
          useCase: ["WaitRefundOrder_Search", "WaitRefundOrder_Search"],
        },
      },
      {
        name: "RefundedOrder",
        path: "RefundedOrder",
        component: _import("order/refunded-order.vue"),
        meta: {
          title: "已退款",
          access: ["RefundedOrder"],
          useCase: ["Order_Search"],
        },
      },
      {
        name: "PayLog",
        path: "PayLog",
        component: _import("order/pay-log.vue"),
        meta: {
          title: "支付记录",
          access: ["PayLog"],
          useCase: ["PayLog_Edit"],
        },
      },
      // {
      //   name: "ExceptionOrder",
      //   path: "ExceptionOrder",
      //   component: _import("order/order.vue"),
      //   meta: {
      //     title: "异常订单",
      //     access: ["ExceptionOrder"],
      //     useCase: ["Order_Search", "Order_Edit"],
      //   },
      // },
      // {
      //   name: "WaitShipProduct",
      //   path: "WaitShipProduct",
      //   component: _import("order/wait-ship-product.vue"),
      //   meta: {
      //     title: "待发货商品",
      //     access: ["WaitShipProduct"],
      //     useCase: ["WaitShipProduct_Search"],
      //   },
      // },
      // {
      //   name: "ShippedProduct",
      //   path: "ShippedProduct",
      //   component: _import("order/shipped-product.vue"),
      //   meta: {
      //     title: "已发货商品",
      //     access: ["ShippedProduct"],
      //     useCase: ["ShippedProduct_Search"],
      //   },
      // },
      // {
      //   name: "ReturnedProduct",
      //   path: "ReturnedProduct",
      //   component: _import("order/returned-product.vue"),
      //   meta: {
      //     title: "已退货商品",
      //     access: ["ReturnedProduct"],
      //     useCase: ["ReturnedProduct_Search"],
      //   },
      // },
    ],
  },
  // 奖金管理
  {
    name: "Bonus",
    path: "/Bonus",
    component: Main,
    meta: {
      title: "奖金管理",
      icon: "ios-paper",
      access: ["BonusManage"],
      showAlways: true,
    },
    children: [
      {
        name: "BonusList",
        path: "BonusList",
        component: _import("bonus/bonus.vue"),
        meta: {
          title: "奖金列表",
          access: ["BonusList"],
          useCase: ["Bonus_Search"],
        },
      },
      {
        name: "BonusDetail",
        path: "BonusDetail",
        component: _import("bonus/bonus-detail.vue"),
        meta: {
          title: "奖金明细",
          access: ["BonusDetail"],
        },
      },
      {
        name: "ExpectBonusDetail",
        path: "ExpectBonusDetail",
        component: _import("bonus/expect-bonus-detail.vue"),
        meta: {
          title: "预估奖金",
          access: ["ExpectBonusDetail"],
        },
      },
      {
        name: "BonusDraw",
        path: "BonusDraw",
        component: _import("bonus/bonus-draw.vue"),
        meta: {
          title: "奖金提现",
          access: ["BonusDraw"],
          useCase: ["BonusDraw_Search", "BonusDraw_Deal", "BonusDraw_Deal"],
        },
      },
      {
        name: "StatManage",
        path: "StatManage",
        component: _import("bonus/stat.vue"),
        meta: {
          title: "业绩统计",
          access: ["StatManage"],
          useCase: ["Stat_Search"],
        },
      },
    ],
  },
  // 统计管理
  {
    name: "Report",
    path: "/Report",
    component: Main,
    meta: {
      title: "统计报表",
      icon: "ios-pie",
      access: ["ReportManage"],
      showAlways: true,
    },
    children: [
      {
        name: "PayStatis",
        path: "PayStatis",
        component: _import("report/pay-statis.vue"),
        meta: {
          title: "支付统计",
          access: ["PayStatis"],
          useCase: ["PayStatis_View"],
        },
      },
    ],
  },
  // 基础配置
  {
    name: "BasicConfig",
    path: "/BasicConfig",
    component: Main,
    meta: {
      title: "基础配置",
      icon: "md-cog",
      access: ["BasicConfig"],
      showAlways: true,
    },
    children: [
      {
        name: "MemberLevel",
        path: "MemberLevel",
        component: _import("config/member-level.vue"),
        meta: {
          title: "会员等级",
          access: ["MemberLevel"],
          useCase: ["Member_Level_Edit"],
        },
      },
      {
        name: "ExpressManage",
        path: "ExpressManage",
        component: _import("config/express.vue"),
        meta: {
          title: "快递公司",
          access: ["ExpressManage"],
          useCase: ["Express_Edit", "Express_Del"],
        },
      },
      {
        name: "PayConfig",
        path: "PayConfig",
        component: _import("config/pay-config.vue"),
        meta: {
          title: "支付配置",
          access: ["PayConfig"],
          useCase: ["Pay_Config_Edit"],
        },
      },
      // {
      //   name: "BankConfig",
      //   path: "BankConfig",
      //   component: _import("config/bank.vue"),
      //   meta: {
      //     title: "银行配置",
      //     access: ["BankConfig"],
      //     useCase: ["Bank_Config_Edit", "Bank_Config_Del"],
      //   },
      // },
      // {
      //   name: "BonusConfig",
      //   path: "BonusConfig",
      //   component: _import("config/bonus-config.vue"),
      //   meta: {
      //     title: "提成配置",
      //     access: ["BonusConfig"],
      //   },
      // },
      {
        name: "AdvertManage",
        path: "AdvertManage",
        component: _import("config/advert.vue"),
        meta: {
          title: "广告管理",
          access: ["AdvertManage"],
          useCase: ["Advert_Search", "Advert_Edit", "Advert_Del"],
        },
      },
      {
        name: "AdvertPosition",
        path: "AdvertPosition",
        component: _import("config/advert-position.vue"),
        meta: {
          title: "广告位置管理",
          access: ["AdvertPosition"],
          useCase: ["AdvertPosition_Search"],
        },
      },
      {
        name: "SystemConfig",
        path: "SystemConfig",
        component: _import("config/sys-config.vue"),
        meta: {
          title: "系统配置",
          access: ["SystemConfig"],
          useCase: ["Sys_Config_Edit"],
        },
      },
    ],
  },
  // 系统管理
  {
    name: "System",
    path: "/System",
    component: Main,
    meta: {
      title: "系统管理",
      icon: "md-settings",
      access: ["SystemManage"],
      showAlways: true,
    },
    children: [
      {
        name: "MenuManage",
        path: "MenuManage",
        component: _import("system/menu.vue"),
        meta: { title: "菜单管理", access: ["MenuManage"] },
      },
      {
        name: "UsecaseManage",
        path: "UsecasManage",
        component: _import("system/use-case.vue"),
        meta: {
          title: "用例管理",
          access: ["UsecaseManage"],
          useCase: ["Sys_UC_Edit", "Sys_UC_Del"],
        },
      },
      {
        name: "RoleManage",
        path: "RoleManage",
        component: _import("system/role.vue"),
        meta: {
          title: "角色管理",
          access: ["RoleManage"],
          useCase: [
            "Sys_Role_Edit",
            "Sys_Role_Del",
            "Sys_Role_Edit",
            "Sys_Role_Edit",
          ],
        },
      },
      {
        name: "UserManage",
        path: "UserManage",
        component: _import("system/user.vue"),
        meta: {
          title: "用户管理",
          access: ["UserManage"],
          useCase: ["Sys_User_Edit", "Sys_User_SetRole"],
        },
      },
      {
        name: "CodeManage",
        path: "CodeManage",
        component: _import("system/code-rule.vue"),
        meta: {
          title: "系统编码",
          access: ["CodeManage"],
          useCase: ["Sys_Code_Edit", "Sys_Code_Del"],
        },
      },
      {
        name: "CacheManage",
        path: "CacheManage",
        component: _import("system/cache.vue"),
        meta: {
          title: "缓存管理",
          access: ["CacheManage"],
          useCase: [
            "Sys_Cache_Edit",
            "Sys_Cache_Search",
            "Sys_Cache_Edit",
            "Sys_Cache_Del",
          ],
        },
      },
      {
        name: "JobManage",
        path: "JobManage",
        component: _import("system/job-config.vue"),
        meta: {
          title: "JOB管理",
          access: ["JobManage"],
          useCase: ["Sys_Job_Search", "Sys_Job_Edit", "Sys_Job_Del"],
        },
      },
    ],
  },
  // 日志管理
  {
    name: "Log",
    path: "/Log",
    component: Main,
    meta: {
      title: "日志管理",
      icon: "md-warning",
      access: ["LogManage"],
      showAlways: true,
    },
    children: [
      {
        name: "LoginLog",
        path: "LoginLog",
        component: _import("log/login-log.vue"),
        meta: {
          title: "登录日志",
          access: ["LoginLog"],
        },
      },
      {
        name: "OperateLog",
        path: "OperateLog",
        component: _import("log/operate-log.vue"),
        meta: {
          title: "操作日志",
          access: ["OperateLog"],
          useCase: ["Log_Operate_Search"],
        },
      },
      {
        name: "ExceptionLog",
        path: "ExceptionLog",
        component: _import("log/exception-log.vue"),
        meta: {
          title: "异常日志",
          access: ["ExceptionLog"],
          useCase: ["Log_Exception_Search"],
        },
      },
    ],
  },
];

export default routes;

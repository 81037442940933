import store from "@/store";
import Vue from "vue";

/**
 * Action 权限指令
 * 指令用法：
 *  - 在需要控制 action 级别权限的组件上使用 v-action:[method] , 如下：
 *    <i-button v-action:add >添加用户</a-button>
 *    <a-button v-action:delete>删除用户</a-button>
 *    <a v-action:edit @click="edit(record)">修改</a>
 */
const action = Vue.directive("action", {
  inserted: function (el, binding) {
    const actionName = binding.arg;
    // const roles = store.getters.roles
    // const elVal = vnode.context.$route.meta.useCase;
    // const permissionId = (elVal instanceof String && [elVal]) || elVal;
    const useCaseList = store.state.user.useCaseList;
    if (!useCaseList.includes(actionName)) {
      (el.parentNode && el.parentNode.removeChild(el)) ||
        (el.style.display = "none");
    }
    // roles.permissions.forEach(p => {
    //   if (!permissionId.includes(p.permissionId)) {
    //     return
    //   }
    //   if (p.actionList && !p.actionList.includes(actionName)) {
    //     el.parentNode && el.parentNode.removeChild(el) || (el.style.display = 'none')
    //   }
    // })
  },
});

export default action;

import api from "@/api";
import types from "@/store/mutation-types";

export default {
  getIndexData: async ({ commit }) => {
    try {
      const res = await api.GetIndexData();
      commit(types.SET_INDEX_DATA, res);
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getCount: async ({ commit }) => {
    try {
      const res = await api.GetCount();
      commit(types.SET_COUNT, res);
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getLoginLogByLoginName: async ({ commit }, payload) => {
    try {
      const res = await api.GetLoginLogByLoginName(payload);
      commit(types.SET_LAST_LOGIN_LOG, res);
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getAddressList: async ({ commit }, payload) => {
    try {
      const res = await api.GetAddressList(payload);
      commit(types.SET_ADRESS_LIST, res);
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

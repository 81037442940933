import routes from "@/router/routes";
import { getMenuByRouter, getRouteTitleHandled, routeEqual } from "@/utils";
import types from "@/store/mutation-types";
import { getStorage, setStoreage, storageName } from "@/utils/storage";
import { HomeName } from "@/constant";
import router from "@/router";

/**
 * @param {Array} list 标签列表
 * @param {String} name 当前关闭的标签的name
 */
export const getNextRoute = (list, route) => {
  let res = {};
  if (list.length === 2) {
    res = getHomeRoute(list);
  } else {
    const index = list.findIndex((item) => routeEqual(item, route));
    if (index === list.length - 1) res = list[list.length - 2];
    else res = list[index + 1];
  }
  return res;
};

const closePage = (state, route) => {
  const nextRoute = getNextRoute(state.tagNavList, route);
  state.tagNavList = state.tagNavList.filter((item) => {
    return !routeEqual(item, route);
  });
  router.push(nextRoute);
};

/**
 * @param {Array} routers 路由列表数组
 * @description 用于找到路由列表中name为home的对象
 */
const getHomeRoute = (routers, homeName = HomeName) => {
  let i = -1;
  let len = routers.length;
  let homeRoute = {};
  while (++i < len) {
    let item = routers[i];
    if (item.children && item.children.length) {
      let res = getHomeRoute(item.children, homeName);
      if (res.name) return res;
    } else {
      if (item.name === homeName) homeRoute = item;
    }
  }
  return homeRoute;
};

/**
 * @param {Number} times 回调函数需要执行的次数
 * @param {Function} callback 回调函数
 */
export const doCustomTimes = (times, callback) => {
  let i = -1;
  while (++i < times) {
    callback(i);
  }
};

/**
 * 判断打开的标签列表里是否已存在这个新添加的路由对象
 */
export const routeHasExist = (tagNavList, routeItem) => {
  let len = tagNavList.length;
  let res = false;
  doCustomTimes(len, (index) => {
    if (routeEqual(tagNavList[index], routeItem)) res = true;
  });
  return res;
};

/**
 * @param {Array} routeMetched 当前路由metched
 * @returns {Array}
 */
const getBreadCrumbList = (route, homeRoute) => {
  let homeItem = { ...homeRoute, icon: homeRoute.meta.icon };
  let routeMetched = route.matched;
  if (routeMetched.some((item) => item.name === homeRoute.name)) {
    return [homeItem];
  }
  let res = routeMetched
    .filter((item) => {
      return item.meta === undefined || !item.meta.hideInBread;
    })
    .map((item) => {
      let meta = { ...item.meta };
      if (meta.title && typeof meta.title === "function") {
        meta.__titleIsFunction__ = true;
        meta.title = meta.title(route);
      }
      let obj = {
        icon: (item.meta && item.meta.icon) || "",
        name: item.name,
        meta: meta,
      };
      return obj;
    });
  res = res.filter((item) => {
    return !item.meta.hideInMenu;
  });
  return [{ ...homeItem, to: homeRoute.path }, ...res];
};

export const state = {
  breadCrumbList: [],
  homeRoute: {},
  tagNavList: [],
  addressJson: null,
  qiniuToken: null,
};
export const mutations = {
  [types.SET_HOME_ROUTE](state, routes) {
    state.homeRoute = getHomeRoute(routes, HomeName);
  },
  [types.SET_BREAD_CRUMB](state, route) {
    state.breadCrumbList = getBreadCrumbList(route, state.homeRoute);
  },
  [types.SET_TAG_NAV_LIST](state, list) {
    let tagList = [];
    if (list) {
      tagList = [...list];
    } else tagList = getStorage(storageName.tagNavList) || [];
    if (tagList[0] && tagList[0].name !== HomeName) tagList.shift();
    let homeTagIndex = tagList.findIndex((item) => item.name === HomeName);
    if (homeTagIndex > 0) {
      let homeTag = tagList.splice(homeTagIndex, 1)[0];
      tagList.unshift(homeTag);
    }
    state.tagNavList = tagList;
    setStoreage(storageName.tagNavList, tagList);
  },
  [types.ClOSE_TAG](state, route) {
    let tag = state.tagNavList.filter((item) => routeEqual(item, route));
    route = tag[0] ? tag[0] : null;
    if (!route) return;
    closePage(state, route);
  },
  [types.ADD_TAG](state, { route, type = "unshift" }) {
    let router = getRouteTitleHandled(route);
    if (!routeHasExist(state.tagNavList, router)) {
      if (type === "push") state.tagNavList.push(router);
      else {
        if (router.name === HomeName) state.tagNavList.unshift(router);
        else state.tagNavList.splice(1, 0, router);
      }
      setStoreage(storageName.tagNavList, [...state.tagNavList]);
    }
  },
  [types.SET_ADRESS_LIST](state, data) {
    state.addressJson = data;
  },
  [types.SET_QIUNIU_TOKEN](state, data) {
    state.qiniuToken = data.token;
  },
};
export const getters = {
  menuList: (state, getters, rootState) => {
    return getMenuByRouter(routes, rootState.user.access);
  },
  addressList: (state) => {
    let arr = [];
    if (!state.addressJson) return null;
    state.addressJson.forEach((item) => {
      let arr1 = [];
      item.cities.forEach((c) => {
        let arr2 = [];
        c.counties.forEach((cou) => {
          arr2.push({
            value: cou.areaId,
            label: cou.areaName,
          });
        });
        arr1.push({
          label: c.areaName,
          value: c.areaId,
          children: arr2,
        });
      });
      arr.push({
        label: item.areaName,
        value: item.areaId,
        children: arr1,
      });
    });
    return arr;
  },
};

export const HOST = {
  ADMIN_API_URL: process.env.VUE_APP_ADMIN_API_URL,
  LOG_API_URL: process.env.VUE_APP_LOG_API_URL,
  IMAGE_SERVER: process.env.VUE_APP_IMAGE_SERVER,
  IMAGE_SERVER_OLD: process.env.VUE_APP_IMAGE_SERVER_OLD,
  QINIU_UPLOAD_URL: process.env.VUE_APP_QINIU_UPLOAD_URL,
};

export const CONFIG = {
  LOAD_DELAY_TIME: 1000, // 延迟加载延迟时间，单位毫秒
};

export const HomeName = "Home";

<template>
  <Layout class="layout-container">
    <Sider
      hide-trigger
      collapsible
      :width="195"
      :collapsed-width="64"
      v-model="collapsed"
      class="left-sider"
    >
      <side-menu
        accordion
        ref="sideMenu"
        :active-name="$route.name"
        :collapsed="collapsed"
        @on-select="turnToPage"
        :menu-list="menuList"
      >
        <div class="logo-con">
          <img v-show="!collapsed" :src="logo" key="max-logo" />
          <img
            v-show="collapsed"
            src="~@/assets/images/logo-min.png"
            key="min-logo"
            style="width: 50px; height: 50px; margin-top: 10px"
          />
        </div>
      </side-menu>
    </Sider>
    <Layout>
      <Header class="header-con">
        <header-bar
          :collapsed="collapsed"
          @on-coll-change="handleCollapsedChange"
        >
          <user :user-avatar="userAvatar" />
          <fullscreen v-model="isFullscreen" class="refresh-icon" />
          <Tooltip class="refresh-icon" content="刷新" placement="bottom">
            <Icon type="md-refresh" :size="23" @click="onRefresh" />
          </Tooltip>

          <h4 style="margin-right: 10px; font-size: 16px">{{ realName }}</h4>
        </header-bar>
      </Header>
      <Content class="main-content-con">
        <Layout class="main-content-con">
          <div class="tag-nav-wrapper">
            <tags-nav
              :value="$route"
              @input="handleClick"
              :list="tagNavList"
              @on-close="handleCloseTag"
            />
          </div>
          <Content class="content-wrapper">
            <keep-alive :include="cacheList">
              <router-view />
            </keep-alive>
          </Content>
        </Layout>
      </Content>
    </Layout>
  </Layout>
</template>

<script>
import { mapGetters, mapMutations, mapState, mapActions } from "vuex";
import sideMenu from "./side-menu/side-menu.vue";
import headerBar from "./header-bar/header-bar.vue";
import user from "./user/user.vue";
import fullscreen from "./fullscreen/fullscreen.vue";
import tagsNav from "./tags-nav/tags-nav.vue";
import { HomeName, HOST } from "@/constant";
import types from "@/store/mutation-types";
import routes from "@/router/routes";
import { getNewTagList, routeEqual } from "@/utils";
export default {
  name: "Main",
  components: { headerBar, user, sideMenu, tagsNav, fullscreen },
  data() {
    this.logo = require(`../../public/images/logo_${process.env.VUE_APP_PLATFORM}.png`);
    return {
      collapsed: false,
      isFullscreen: false,
    };
  },
  computed: {
    ...mapGetters(["menuList"]),
    ...mapState({
      realName: (state) => state.user.userInfo.RealName,
      tagNavList: (state) => state.app.tagNavList,
      userAvatar: (state) =>
        state.user.userInfo.Avatar.includes("ssdms_")
          ? `${HOST.IMAGE_SERVER}${state.user.userInfo.Avatar}`
          : `${HOST.IMAGE_SERVER_OLD}${state.user.userInfo.Avatar}`,
    }),
    cacheList() {
      const list = [
        "ParentView",
        ...(this.tagNavList.length
          ? this.tagNavList
              .filter((item) => !(item.meta && item.meta.notCache))
              .map((item) => item.name)
          : []),
      ];
      return list;
    },
  },
  methods: {
    ...mapMutations([
      types.SET_HOME_ROUTE,
      types.SET_BREAD_CRUMB,
      types.SET_TAG_NAV_LIST,
      types.ADD_TAG,
      types.ClOSE_TAG,
    ]),
    ...mapActions(["getAddressList"]),
    onRefresh() {
      this.$bus.$emit("refreshPage");
    },
    turnToPage(route) {
      let { name, params, query } = {};
      if (typeof route === "string") name = route;
      else {
        name = route.name;
        params = route.params;
        query = route.query;
      }
      if (name.indexOf("isTurnByHref_") > -1) {
        window.open(name.split("_")[1]);
        return;
      }
      this.$router.push({
        name,
        params,
        query,
      });
    },
    handleCollapsedChange(state) {
      this.collapsed = state;
    },
    handleClick(item) {
      this.turnToPage(item);
    },
    handleCloseTag(res, type, route) {
      if (type !== "others") {
        if (type === "all") {
          this.turnToPage(HomeName);
        } else {
          if (routeEqual(this.$route, route)) {
            this[types.ClOSE_TAG](route);
          }
        }
      }
      this[types.SET_TAG_NAV_LIST](res);
    },
  },

  watch: {
    $route(newRoute) {
      const { name, query, params, meta } = newRoute;
      this[types.ADD_TAG]({
        route: { name, query, params, meta },
        type: "push",
      });
      this[types.SET_BREAD_CRUMB](newRoute);
      this[types.SET_TAG_NAV_LIST](getNewTagList(this.tagNavList, newRoute));
      this.$refs.sideMenu.updateOpenName(newRoute.name);
    },
  },
  mounted() {
    this[types.SET_TAG_NAV_LIST]();
    this[types.SET_HOME_ROUTE](routes);

    const { name, params, query, meta } = this.$route;
    this[types.ADD_TAG]({
      route: { name, query, params, meta },
      type: "push",
    });

    this[types.SET_BREAD_CRUMB](this.$route);
    // 如果当前打开页面不在标签栏中，跳到homeName页
    if (!this.tagNavList.find((item) => item.name === this.$route.name)) {
      this.$router.push({
        name: HomeName,
      });
    }
    this.getAddressList();
  },
};
</script>

<style lang="less">
.layout-container {
  height: 100vh;
  .logo-con {
    height: 64px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    img {
      height: 64px;
      width: auto;
      display: block;
    }
  }
  .header-con {
    background: #fff;
    padding: 0 20px;
    width: 100%;
    .refresh-icon {
      text-align: center;
      margin-right: 15px;
      line-height: 70px;
      cursor: pointer;
    }
  }
  .main-layout-con {
    height: 100%;
    overflow: hidden;
  }
  .main-content-con {
    height: ~"calc(100vh - 60px)";
    overflow: hidden;
  }
  .main {
    .ivu-tooltip {
      line-height: normal;
    }
  }
  .tag-nav-wrapper {
    padding: 0;
    height: 40px;
    background: #f0f0f0;
  }
  .content-wrapper {
    padding: 10px 18px;
    height: ~"calc(100% - 80px)";
    overflow: auto;
  }

  .left-sider {
    overflow: hidden;
    .side-menu-wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      .ivu-menu.ivu-menu-dark.ivu-menu-vertical {
        width: 100% !important;
        flex: 1;
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 0 !important;
        }
      }
      a.drop-menu-a {
        display: inline-block;
        padding: 12px 24px 12px 24px;
        width: 100%;
        text-align: center;
        color: #495060;
      }
    }
  }
}
</style>

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ViewUI from "view-design";
import "./index.less";
import "@/directives/action";
import { canAction } from "./utils/permission";
import { HOST } from "@/constant";
import {
  getTableHeight,
  getCacheUpdatedTime,
  getAddressCodeToString,
  handleHiddenSearch,
  importCache,
  setImagePrefix,
  delayLoad,
} from "./utils";
import bus from "./utils/bus";
import BigJS from "./utils/big";

Vue.config.productionTip = false;

Vue.use(ViewUI);

Vue.prototype.$getTableHeight = getTableHeight;
Vue.prototype.$canAction = canAction;
Vue.prototype.$importCache = importCache;
Vue.prototype.$getCacheUpdatedTime = getCacheUpdatedTime;
Vue.prototype.$getAddressCodeToString = getAddressCodeToString;
Vue.prototype.$bus = bus;
Vue.prototype.$big = BigJS;
Vue.prototype.$handleHiddenSearch = handleHiddenSearch;
Vue.prototype.$setImagePrefix = setImagePrefix;
Vue.prototype.$delayLoad = delayLoad;
Vue.prototype.$host = HOST;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

import { getStorage, setStoreage, storageName, token } from "@/utils/storage";
import types from "@/store/mutation-types";
export const state = {
  userInfo: getStorage(storageName.userInfo) || null,
  useCaseList: getStorage(storageName.useCaseList) || null,
  access: getStorage(storageName.userModuleList) || null,
};
export const mutations = {
  [types.SET_USER_INFO](state, payload) {
    state.userInfo = payload;
    state.access = payload.UserModuleList;
    state.useCaseList = payload.UsecaseList;
    setStoreage(token, state.userInfo.Token);
    setStoreage(storageName.userInfo, state.userInfo);
    setStoreage(storageName.userModuleList, state.access);
    setStoreage(storageName.useCaseList, state.useCaseList);
  },
};

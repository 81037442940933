import { HOST } from "@/constant";
import request from "@/utils/request";

const bonus = `${HOST.ADMIN_API_URL}/Bonus`;

export const GetMemberBonusList = (data) => {
  return request({
    url: `${bonus}/GetMemberBonusList`,
    method: "GET",
    data,
  });
};
export const GetMemberStatList = (data) => {
  return request({
    url: `${bonus}/GetMemberStatList`,
    method: "GET",
    data,
  });
};
export const SaveMemberBonus = (data) => {
  return request({
    url: `${bonus}/SaveMemberBonus`,
    method: "POST",
    data,
  });
};
export const GetBonusDetailList = (data) => {
  return request({
    url: `${bonus}/GetBonusDetailList`,
    method: "GET",
    data,
  });
};
export const GetExpectBonusDetailList = (data) => {
  return request({
    url: `${bonus}/GetExpectBonusDetailList`,
    method: "GET",
    data,
  });
};
export const ExportBonusDetail = (data) => {
  return request({
    url: `${bonus}/ExportBonusDetail`,
    method: "POST",
    responseType: "arraybuffer",
    data,
  });
};
export const SaveBonusDetail = (data) => {
  return request({
    url: `${bonus}/SaveBonusDetail`,
    method: "POST",
    data,
  });
};
export const GetBonusDrawList = (data) => {
  return request({
    url: `${bonus}/GetBonusDrawList`,
    method: "GET",
    data,
  });
};
export const SaveBonusDraw = (data) => {
  return request({
    url: `${bonus}/SaveBonusDraw`,
    method: "POST",
    data,
  });
};
export const PayBonusDraw = (data) => {
  return request({
    url: `${bonus}/PayBonusDraw`,
    method: "POST",
    data,
  });
};
export const ExportBonusDrawList = (data) => {
  return request({
    url: `${bonus}/ExportBonusDrawList`,
    method: "POST",
    responseType: "arraybuffer",
    data,
  });
};

import types from "@/store/mutation-types";

export const state = {
  brandList: null,
  classList: null,
  functionList: null,
};
export const mutations = {
  [types.SET_PRODUCT_BRAND](state, data) {
    state.brandList = data;
  },
  [types.SET_PRODUCT_CLASS](state, data) {
    state.classList = data;
  },
};

<template>
  <div class="user-avatar-dropdown">
    <Dropdown @on-click="handleClick">
      <Badge :dot="false">
        <Avatar :src="userAvatar" />
      </Badge>
      <Icon :size="18" type="md-arrow-dropdown" />
      <DropdownMenu slot="list">
        <DropdownItem name="editPassword">修改密码</DropdownItem>
        <DropdownItem name="logout">退出登录</DropdownItem>
      </DropdownMenu>
    </Dropdown>
    <Modal
      :mask-closable="false"
      v-model="editPasswordModal"
      title="修改密码"
      class-name="i-modal"
      :width="520"
    >
      <Form
        ref="editPasswordForm"
        :model="editPasswordForm"
        :label-width="100"
        label-position="right"
        :rules="passwordValidate"
      >
        <FormItem label="原密码" prop="oldPass" :error="oldPassError">
          <Input
            v-model="editPasswordForm.oldPass"
            placeholder="请输入现在使用的密码"
          ></Input>
        </FormItem>
        <FormItem label="新密码" prop="newPass">
          <Input
            v-model="editPasswordForm.newPass"
            placeholder="请输入新密码，仅支持6-20位英文、数字或符号组合"
          ></Input>
        </FormItem>
        <FormItem label="确认新密码" prop="rePass">
          <Input
            v-model="editPasswordForm.rePass"
            placeholder="请再次输入新密码"
          ></Input>
        </FormItem>
      </Form>
      <div slot="footer">
        <Button type="text" @click="editPasswordModal = false">取消</Button>
        <Button type="primary" :loading="savePassLoading" @click="saveEditPass"
          >保存</Button
        >
      </div>
    </Modal>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "User",
  props: {
    userAvatar: { type: String, default: "" },
  },
  data() {
    const valideRePassword = (rule, value, callback) => {
      if (value !== this.editPasswordForm.newPass) {
        callback(new Error("两次输入密码不一致"));
      } else {
        callback();
      }
    };
    const validePassword = (rule, value, callback) => {
      if (value === this.editPasswordForm.oldPass)
        callback(new Error("新密码不能与旧密码一样"));
      if (value.length < 6 || value.length > 20)
        callback(new Error("密码长度为6到20位"));
      if (value.indexOf(" ") >= 0)
        callback(new Error("密码中不能含有空格符号"));
      if (
        !/[^\uFF00-\uFFFF]/.test(value) ||
        !/((?=.*\d)(?=.*\D)|(?=.*[a-zA-Z])(?=.*[^a-zA-Z]))(?!^.*[\u4E00-\u9FA5].*$)^\S{6,20}$/.test(
          value
        )
      ) {
        callback(
          new Error(
            "密码须为6到20位英文字母、数字或符号的组合，并且至少包含其中2种"
          )
        );
      } else {
        callback();
      }
    };
    return {
      save_loading: false,
      editPasswordModal: false, // 修改密码模态框显示
      savePassLoading: false,
      oldPassError: "",
      editPasswordForm: {
        oldPass: "",
        newPass: "",
        rePass: "",
      },
      passwordValidate: {
        oldPass: [{ required: true, message: "请输入原密码", trigger: "blur" }],
        newPass: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          { validator: validePassword, trigger: "blur" },
        ],
        rePass: [
          { required: true, message: "请再次输入确认新密码", trigger: "blur" },
          { validator: valideRePassword, trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    ...mapActions(["handleLogout", "editPassword"]),
    logout() {
      this.handleLogout().then(() => {});
    },
    openPassword() {
      this.editPasswordModal = true;
      this.editPasswordForm = { oldPass: "", newPass: "", rePass: "" };
    },
    saveEditPass() {
      const _this = this;
      _this.$refs["editPasswordForm"].validate(async (valid) => {
        if (valid) {
          try {
            _this.savePassLoading = true;
            const res = await _this.editPassword({
              oldp: _this.editPasswordForm.oldPass,
              newp: _this.editPasswordForm.newPass,
            });
            if (res.Status === 100) {
              _this.savePassLoading = false;
              _this.$Message.success("修改成功！");
              _this.editPasswordModal = false;
              _this.handleLogout();
              _this.$router.push({
                name: "Login",
              });
            }
          } catch (err) {
            this.savePassLoading = false;
            this.$Notice.error({
              title: "错误",
              desc: err,
            });
          }
        }
      });
    },
    handleClick(name) {
      switch (name) {
        case "logout":
          this.logout();
          break;
        case "editPassword":
          this.openPassword();
          break;
      }
    },
  },
};
</script>
<style lang="less">
.user {
  &-avatar-dropdown {
    cursor: pointer;
    display: inline-block;
    // height: 64px;
    vertical-align: middle;
    // line-height: 64px;
    .ivu-badge-dot {
      top: 16px;
    }
  }
}
</style>

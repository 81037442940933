import { HOST } from "@/constant";
import request from "@/utils/request";

const system = `${HOST.ADMIN_API_URL}/System`;
const cache = `${HOST.ADMIN_API_URL}/Cache`;

export const Login = (data) => {
  return request({
    url: `${system}/Login`,
    method: "POST",
    data,
  });
};
export const Logout = () => {
  return request({
    url: `${system}/Logout`,
    method: "POST",
  });
};
export const EditPassWord = (data) => {
  return request({
    url: `${system}/EditPassWord`,
    method: "POST",
    data,
  });
};
/**
 * 获取系统所有菜单
 * @returns
 */
export const GetAllModuleList = () => {
  return request({
    url: `${system}/GetAllModuleList`,
    method: "GET",
  });
};
// 生成业务编号串号
export const GenerateSystemCode = (data) => {
  return request({
    url: `${system}/GenerateSystemCode`,
    method: "POST",
    data,
  });
};
export const SaveModule = (data) => {
  return request({
    url: `${system}/SaveModule`,
    method: "POST",
    data,
  });
};
export const DeleteModule = (data) => {
  return request({
    url: `${system}/DeleteModule`,
    method: "POST",
    data,
  });
};
export const GetCacheUpdatedTime = (data) => {
  return request({
    url: `${system}/GetCacheUpdatedTime`,
    method: "POST",
    data,
  });
};
export const ImportRedis = (data) => {
  return request({
    url: `${cache}/ImportRedis`,
    method: "POST",
    data,
  });
};
export const GetQiniuToken = () => {
  return request({
    url: `${system}/GetQiniuToken`,
    method: "POST",
  });
};
// 保存用例
export const SaveModuleUsecase = (data) => {
  return request({
    url: `${system}/SaveModuleUsecase`,
    method: "POST",
    data,
  });
};
// 删除用例
export const DeleteModuleUsecase = (data) => {
  return request({
    url: `${system}/DeleteModuleUsecase`,
    method: "POST",
    data,
  });
};
// 根据菜单ID获取用例列表
export const GetUsecaseByMenu = (data) => {
  return request({
    url: `${system}/GetUsecaseByMenu`,
    method: "GET",
    data,
  });
};
// 保存角色
export const SaveSysRole = (data) => {
  return request({
    url: `${system}/SaveSysRole`,
    method: "POST",
    data,
  });
};
// 删除角色
export const DeleteRole = (data) => {
  return request({
    url: `${system}/DeleteRole`,
    method: "GET",
    data,
  });
};
// 设置角色用例
export const SetModuleUsecaseToRole = (data) => {
  return request({
    url: `${system}/SetModuleUsecaseToRole`,
    method: "POST",
    data,
  });
};
// 添加某用户进某角色组
export const AddUserIntoRole = (data) => {
  return request({
    url: `${system}/AddUserIntoRole`,
    method: "POST",
    data,
  });
};
// 获取用例列表
export const GetUsecaseAndRoleCaseList = (data) => {
  return request({
    url: `${system}/GetUsecaseAndRoleCaseList`,
    method: "GET",
    data,
  });
};
// 获取分页角色列表
export const GetPagedSysRoleList = (data) => {
  return request({
    url: `${system}/GetPagedSysRoleList`,
    method: "GET",
    data,
  });
};
// 获取角色下用户
export const GetUserListByRole = (data) => {
  return request({
    url: `${system}/GetUserListByRole`,
    method: "GET",
    data,
  });
};
// 批量设置角色用户
export const SetUserToRole = (data) => {
  return request({
    url: `${system}/SetUserToRole`,
    method: "POST",
    data,
  });
};
// 保存用户信息
export const SaveUser = (data) => {
  return request({
    url: `${system}/SaveUser`,
    method: "POST",
    data,
  });
};
// 获取角色列表
export const GetSysRoleList = (data) => {
  return request({
    url: `${system}/GetSysRoleList`,
    method: "GET",
    data,
  });
};
// 根据用户ID获取角色组
export const GetRoleListByUser = (data) => {
  return request({
    url: `${system}/GetRoleListByUser`,
    method: "GET",
    data,
  });
};
// 获取用户列表
export const GetUserList = (data) => {
  return request({
    url: `${system}/GetUserList`,
    method: "GET",
    data,
  });
};
// 给用户设置角色组
export const SetRoleByUser = (data) => {
  return request({
    url: `${system}/SetRoleByUser`,
    method: "POST",
    data,
  });
};
// 获取编码列表
export const GetCodeRuleList = (data) => {
  return request({
    url: `${system}/GetCodeRuleList`,
    method: "GET",
    data,
  });
};
// 保存编码
export const SaveCodeRule = (data) => {
  return request({
    url: `${system}/SaveCodeRule`,
    method: "POST",
    data,
  });
};
// 删除编码
export const DeleteCodeRule = (data) => {
  return request({
    url: `${system}/DeleteCodeRule`,
    method: "GET",
    data,
  });
};
// 获取缓存列表
export const GetDataCacheList = (data) => {
  return request({
    url: `${system}/GetDataCacheList`,
    method: "GET",
    data,
  });
};
// 删除缓存
export const DeleteDataCache = (data) => {
  return request({
    url: `${system}/DeleteDataCache`,
    method: "POST",
    data,
  });
};
// 清楚缓存
export const ClearDataCache = (data) => {
  return request({
    url: `${system}/ClearDataCache`,
    method: "POST",
    data,
  });
};
// 获取缓存
export const GetRedis = (data) => {
  return request({
    url: `${cache}/GetRedis`,
    method: "GET",
    data,
  });
};
// 修改缓存
export const SaveDataCache = (data) => {
  return request({
    url: `${system}/SaveDataCache`,
    method: "POST",
    data,
  });
};
// 获取job配置
export const GetJobConfigList = (data) => {
  return request({
    url: `${system}/GetJobConfigList`,
    method: "GET",
    data,
  });
};
// 删除job配置
export const DeleteJobConfig = (data) => {
  return request({
    url: `${system}/DeleteJobConfig`,
    method: "POST",
    data,
  });
};
// 保存job配置
export const SaveJobConfig = (data) => {
  return request({
    url: `${system}/SaveJobConfig`,
    method: "POST",
    data,
  });
};

/* eslint-disable no-async-promise-executor */
import api from "@/api";
import types from "@/store/mutation-types";

export default {
  getProductBrandInfo({ commit, state }) {
    return new Promise(async (resolve, reject) => {
      try {
        if (state.brandList) {
          resolve(state.brandList);
        } else {
          const res = await api.GetProductBrandInfo({});
          commit(types.SET_PRODUCT_BRAND, res.Data);
          resolve(res);
        }
      } catch (error) {
        reject(error);
      }
    });
  },
  getProductClassList({ commit, state }) {
    return new Promise(async (resolve, reject) => {
      try {
        if (state.classList) {
          resolve(state.classList);
        } else {
          const res = await api.GetProductClassList();
          commit(types.SET_PRODUCT_CLASS, res.Data);
          resolve(res);
        }
      } catch (error) {
        reject(error);
      }
    });
  },
  getProductClassInfo({ commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.GetProductClassInfo();
        commit(types.SET_PRODUCT_CLASS, res.Data);
        resolve(res);
      } catch (error) {
        reject(error);
      }
    });
  },
};

import api from "@/api";
import router from "@/router";

export default {
  handleLogout: async () => {
    try {
      await api.Logout();
      localStorage.clear();
      router.push({ name: "Login" });
    } catch (error) {
      localStorage.clear();
      router.push({ name: "Login" });
    }
  },
  // eslint-disable-next-line no-unused-vars
  editPassword: async ({ commit }, payload) => {
    try {
      const res = await api.EditPassWord(payload);
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

import { HomeName } from "@/constant";
import store from "@/store";
import { canTurnTo, setTitle } from "@/utils";
import { getStorage, token } from "@/utils/storage";
import { LoadingBar } from "view-design";
import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const turnTo = (to, access, useCaseList, next) => {
  if (canTurnTo) {
    if (to.meta.useCase) {
      const useCase = to.meta.useCase;
      let purviewNum = 0;
      useCase.some((res) => {
        if (useCaseList.indexOf(res) > -1) {
          purviewNum++;
        }
      });
      switch (purviewNum) {
        case 0:
          to.meta.tableActionWidth = 0;
          break;
        case 1:
          to.meta.tableActionWidth = 60;
          break;
        case 2:
          to.meta.tableActionWidth = 105;
          break;
        case 3:
          to.meta.tableActionWidth = 145;
          break;
        case 4:
          to.meta.tableActionWidth = 190;
          break;
        case 5:
          to.meta.tableActionWidth = 230;
          break;
        default:
          to.meta.tableActionWidth = 230;
      }
    }
    next();
  } else {
    next({ replace: true, name: "error_401" });
  } // 无权限，重定向到401页面
};

const router = new VueRouter({
  base: process.env.VUE_APP_PUBLIC_PATH,
  routes,
});

router.beforeEach((to, from, next) => {
  LoadingBar.start();
  const dtToken = getStorage(token);
  if (!dtToken && to.name !== "Login") {
    next({ name: "Login" });
  } else if (!dtToken && to.name === "Login") {
    next();
  } else if (dtToken && to.name === "Login") {
    next({ home: HomeName });
  } else {
    turnTo(to, store.state.user.access, store.state.user.useCaseList, next);

    // if (store.state.user.hasGetInfo) {
    // } else {
    //   next();
    // }
  }
});

router.afterEach((to) => {
  setTitle(to, router.app);
  LoadingBar.finish();
  window.scrollTo(0, 0);
});

export default router;

import { HOST } from "@/constant";
import request from "@/utils/request";

const product = `${HOST.ADMIN_API_URL}/Product`;

export const GetProductBrandList = (data) => {
  return request({
    url: `${product}/GetProductBrandList`,
    method: "GET",
    data,
  });
};
export const DeleteProductBrand = (data) => {
  return request({
    url: `${product}/DeleteProductBrand`,
    method: "POST",
    data,
  });
};
export const GetProductBrandInfo = (data) => {
  return request({
    url: `${product}/GetAllProductBrand`,
    method: "GET",
    data,
  });
};
export const SaveProductBrand = (data) => {
  return request({
    url: `${product}/SaveProductBrand`,
    method: "POST",
    data,
  });
};
export const GetProductClassList = (data) => {
  return request({
    url: `${product}/GetProductClassList`,
    method: "GET",
    data,
  });
};
export const GetProductClassInfo = (data) => {
  return request({
    url: `${product}/GetProductClassInfo`,
    method: "GET",
    data,
  });
};
export const DeleteProductClass = (data) => {
  return request({
    url: `${product}/DeleteProductClass`,
    method: "POST",
    data,
  });
};
export const SaveProductClass = (data) => {
  return request({
    url: `${product}/SaveProductClass`,
    method: "POST",
    data,
  });
};
export const GetProductList = (data) => {
  return request({
    url: `${product}/GetProductList`,
    method: "GET",
    data,
  });
};
export const GetProductInfoById = (data) => {
  return request({
    url: `${product}/GetProductInfoById`,
    method: "GET",
    data,
  });
};
export const CopyProduct = (data) => {
  return request({
    url: `${product}/CopyProduct`,
    method: "POST",
    data,
  });
};
export const DeleteProduct = (data) => {
  return request({
    url: `${product}/DeleteProduct`,
    method: "POST",
    data,
  });
};
export const ChangeSellOut = (data) => {
  return request({
    url: `${product}/ChangeSellOut`,
    method: "POST",
    data,
  });
};
export const BatchUpdateProductStatus = (data) => {
  return request({
    url: `${product}/BatchUpdateProductStatus`,
    method: "POST",
    data,
  });
};
export const BeforeSaveProduct = (data) => {
  return request({
    url: `${product}/BeforeSaveProduct`,
    method: "POST",
    data,
  });
};
export const SaveProduct = (data) => {
  return request({
    url: `${product}/SaveProduct`,
    method: "POST",
    data,
  });
};

import Big from "big.js";
const BigJS = {
  /** - */
  minus(x, y) {
    return new Big(x).minus(y);
  },
  /** + */
  plus(x, y) {
    return new Big(x).plus(y);
  },
  /** % */
  mod(x, y) {
    return new Big(x).mod(y);
  },
  /** * */
  times(x, y) {
    return new Big(x).times(y);
  },
  toFixed(x, number, type) {
    if (type === "small") {
      const val = new Big(x).toFixed(number).split(".");
      return val[0] + ".<small>" + val[1] + "</small>";
    } else {
      return new Big(x).toFixed(number);
    }
  },
  pow(x, y) {
    return new Big(x).pow(y);
  },
  /** / */
  div(x, y) {
    return new Big(x).div(y);
  },
};

export default BigJS;

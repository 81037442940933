import axios from "axios";
import qs from "qs";
import dayjs from "dayjs";
import { Modal, Notice } from "view-design";
import { canAction } from "./permission";
import { getStorage, token } from "./storage";
import router from "@/router";
import store from "@/store";
const request = axios.create({
  timeout: 30000,
  //responseType: "json",
  withCredentials: true,
});

request.interceptors.request.use(
  (obj) => {
    obj.timeout = obj.timeout || 30000;
    obj.responseType = obj.responseType || "json";
    if (obj.useCase && canAction(obj.useCase)) {
      Modal.error({ title: "错误", content: "您没有操作权限，请联系管理员" });
      return Promise.reject({
        Status: 101,
        Msg: "您没有操作权限，请联系管理员",
        Data: null,
      });
    }
    obj.headers["Authorization"] = `Bearer ${getStorage(token)}`;
    obj.headers["Content-Type"] = "application/x-www-form-urlencoded";
    if (obj.method === "get") {
      // Object.keys(data).forEach(key => {
      //   if (moment.isDate(data[key])) {
      //     data[key] = moment(data[key]).format('YYYY-MM-DD HH:mm:ss');
      //   }
      // });
      obj.params = obj.data;
    }
    obj.transformRequest = [
      function (data) {
        data = qs.stringify(data, {
          serializeDate: function (date) {
            return dayjs(date).format("YYYY-MM-DD HH:mm:ss");
          },
        });
        return data;
      },
    ];
    return obj;
  },
  (error) => {
    return Promise.reject(error);
  }
);
request.interceptors.response.use(
  (obj) => {
    // 处理 excel 文件
    if (
      obj.headers &&
      (obj.headers["content-type"] === "application/x-msdownload" ||
        obj.headers["content-type"] ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
    ) {
      let blob = new Blob([obj.data], {
        type: "application/vnd.ms-excel",
      });
      let a = document.createElement("a");
      let url = window.URL.createObjectURL(blob);
      let fileName = `导出文件${dayjs().format("YYYY-MM-DD HH:mm:ss")}.xls`;
      if (obj.config.method === "GET") {
        fileName = obj.config.params.exportName || fileName;
      } else {
        const query = qs.parse(obj.config.data);
        fileName = query.exportName || fileName;
      }
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
      return obj;
    }
    // 处理json文件
    if (obj.config.url.endsWith(".json")) {
      return obj.data;
    }

    if (obj.data.Status !== 100) {
      if (
        obj.data.Msg === "对不起，您没有此操作权限！" ||
        obj.data.Msg === "对不起，您的登录会话已过期，请重新登录！"
      ) {
        // TODO logout
      }
      if (obj.data.Msg) {
        Notice.error({ title: "提示", desc: obj.data.Msg });
      }
      return Promise.reject(obj.data);
    }
    return Promise.resolve(obj.data);
  },
  (responseError) => {
    if (responseError.code === "ECONNABORTED") {
      responseError.message = "连接服务器超时，请重试";
      Notice.error({
        title: "错误",
        desc: "连接服务器超时，请重试",
      });
      return Promise.reject(responseError);
    }

    if (responseError.message === "Network Error") {
      responseError.message = "连接服务器出错，请检查网络是否正常";
      Notice.error({
        title: "错误",
        desc: "连接服务器出错，请检查网络是否正常",
      });
      return Promise.reject(responseError);
    }

    if (responseError && responseError.response) {
      switch (responseError.response.status) {
        case 400:
          responseError.message = "请求错误";
          break;
        case 401:
          responseError.message = "您的登录会话已过期，请退出重新登录";
          break;
        case 403:
          responseError.message = "您的请求过于频繁，请稍后重试";
          break;
        case 404:
          responseError.mesage = `请求地址出错: ${responseError.response.config.url}`;
          break;
        case 408:
          responseError.message = "请求超时，请重试";
          break;
        case 500:
          responseError.message = "服务器内部错误";
          break;
        case 501:
          responseError.message = "服务未实现";
          break;
        case 502:
          responseError.message = "网关错误";
          break;
        case 503:
          responseError.message = "系统维护中，请稍后访问";
          break;
        case 504:
          responseError.message = "网关超时";
          break;
        case 505:
          responseError.message = "HTTP版本不受支持";
          break;
        default:
      }

      if (
        responseError.response.status === 401 ||
        responseError.response.status === 403
      ) {
        Modal.warning({
          title: "错误",
          content: "您的登录会话已过期，请退出重新登录",
        });
        if (responseError.response.config.url.indexOf("Logout") > -1) {
          localStorage.cleart();
          router.push({ name: "Login" });
        } else {
          store.dispatch("handleLogout");
        }
      } else {
        Notice.error({
          title: "错误",
          desc: responseError.message,
        });
      }
      return Promise.reject(responseError);
    }
    return Promise.reject(responseError);
  }
);

export default request;

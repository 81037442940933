import { intersection } from "lodash";
import store from "@/store";

/**
 * 权鉴
 * @param {*} useCase  当前操作权限
 * @returns 用户是否有操作权限
 */
export const canAction = (useCase) => {
  return intersection(store.state.user.useCaseList, useCase).length;
};

import { HOST } from "@/constant";
import request from "@/utils/request";

const log = `${HOST.LOG_API_URL}/Log`;

export const GetExceptionLog = (data) => {
  return request({
    url: `${log}/GetExceptionLog`,
    method: "GET",
    data,
  });
};
export const GetOperateLog = (data) => {
  return request({
    url: `${log}/GetOperateLog`,
    method: "GET",
    data,
  });
};
export const GetLoginLog = (data) => {
  return request({
    url: `${log}/GetLoginLog`,
    method: "GET",
    data,
  });
};

<template>
  <div id="app">
    <router-view v-if="isRouterAlive" />
  </div>
</template>

<script>
export default {
  name: "SIX-DMS",
  data() {
    return {
      isRouterAlive: true,
    };
  },
};
</script>

import types from "@/store/mutation-types";

export const state = {
  LoginStatus: 1,
  IndexData: {},
  LastLoginInfo: {},
  IndexTempData: {
    FanChartOption: {
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b} : {c} ({d}%)",
      },
      legend: {
        orient: "vertical",
        left: "right",
        data: ["H5", "Android", "IOS"],
      },
      series: [
        {
          name: "订单来源",
          type: "pie",
          radius: "66%",
          center: ["50%", "60%"],
          data: [
            {
              value: 0,
              name: "H5",
              itemStyle: { normal: { color: "#9bd598" } },
            },
            {
              value: 0,
              name: "Android",
              itemStyle: { normal: { color: "#ffd58f" } },
            },
            {
              value: 0,
              name: "IOS",
              itemStyle: { normal: { color: "#ab8df2" } },
            },
            {
              value: 0,
              name: "其它",
              itemStyle: { normal: { color: "#e14f60" } },
            },
          ],
          itemStyle: {
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    },
    PolylineChartOption: {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          label: {
            backgroundColor: "#6a7985",
          },
        },
      },
      grid: {
        top: "3%",
        left: "1.2%",
        right: "1%",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: [
        {
          type: "category",
          boundaryGap: false,
          data: ["周一", "周二", "周三", "周四", "周五", "周六", "周日"],
          axisLabel: {
            textStyle: {
              align: "right",
            },
          },
        },
      ],
      yAxis: [
        {
          type: "value",
        },
      ],
      series: [
        {
          name: "有效订单数量",
          type: "line",
          stack: "总量",
          areaStyle: {
            normal: {
              color: "#2d8cf0",
            },
          },
          data: [0, 0, 0, 0, 0, 0, 0],
        },
        {
          name: "订单总销售额",
          type: "line",
          stack: "总量",
          areaStyle: {
            normal: {
              color: "#2d8cf0",
            },
          },
          data: [0, 0, 0, 0, 0, 0, 0],
        },
      ],
    },
  },
  CommonComment: {
    List: [],
    TotalCount: 0,
  },
  CountNum: {
    AuditMemberNum: 0,
    FeedBackNum: 0,
    MBReflectNum: 0,
  },
};
export const mutations = {
  // 获取快捷方式角标
  [types.SET_COUNT](state, data) {
    if (data.Status === 100) {
      state.CountNum.AuditMemberNum = data.Data.AuditMemberNum;
      state.CountNum.FeedBackNum = data.Data.FeedBackNum;
      state.CountNum.MBReflectNum = data.Data.MBReflectNum;
    }
  },
  [types.SET_INDEX_DATA](state, data) {
    if (data.Status === 100) {
      state.IndexData = data.Data;
      if (data.Data && data.Data.HistoryOrder) {
        state.IndexTempData.PolylineChartOption.xAxis[0].data =
          data.Data.HistoryOrder.List.map(
            (item) => item.Year + "-" + item.Month + "-" + item.Day
          );
      }
    }
  },
  [types.SET_LAST_LOGIN_LOG](state, data) {
    if (data.Status === 100) {
      state.LastLoginInfo = data.Data;
    }
  },
  [types.SET_ADRESS_LIST](state, data) {
    state.addressJson = data;
  },
};

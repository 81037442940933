import { HOST } from "@/constant";
import request from "@/utils/request";

const order = `${HOST.ADMIN_API_URL}/Order`;

// 删除订单
export const DeleteOrder = (data) => {
  return request({
    url: `${order}/DeleteOrder`,
    method: "POST",
    data,
  });
};
// 获取订单列表
export const GetOrderList = (data) => {
  return request({
    url: `${order}/GetOrderList`,
    method: "POST",
    data,
  });
};
export const GetOrderByID = (data) => {
  return request({
    url: `${order}/GetOrderByID`,
    method: "POST",
    data,
  });
};
export const GetOrderByCode = (data) => {
  return request({
    url: `${order}/GetOrderByCode`,
    method: "POST",
    data,
  });
};
// 修改订单明细
export const EditOrderDetail = (data) => {
  return request({
    url: `${order}/EditOrderDetail`,
    method: "POST",
    data,
  });
};
// 通过订单ID获取订单详情
export const GetOrderProductList = (data) => {
  return request({
    url: `${order}/GetOrderProductList`,
    method: "POST",
    data,
  });
};
// 根据订单Code获取支付记录
export const GetOrderPayLog = (data) => {
  return request({
    url: `${order}/GetOrderPayLog`,
    method: "POST",
    data,
  });
};
// 根据订单Code获取退款记录
export const GetOrderRefundLog = (data) => {
  return request({
    url: `${order}/GetOrderRefundLog`,
    method: "POST",
    data,
  });
};
// 根据订单Code获取预估奖金记录
export const GetOrderExpectBonusDetail = (data) => {
  return request({
    url: `${order}/GetOrderExpectBonusDetail`,
    method: "POST",
    data,
  });
};
// 待发货导出
export const OriginExportExcel = (data) => {
  return request({
    url: `${order}/OriginExportExcel`,
    method: "POST",
    responseType: "arraybuffer",
    data,
  });
};
// 订单导出记录状态
export const RemarkIsExport = (data) => {
  return request({
    url: `${order}/RemarkIsExport`,
    method: "POST",
    data,
  });
};
// 取消订单前验证
export const BeforeCancelOrder = (data) => {
  return request({
    url: `${order}/BeforeCancelOrder`,
    method: "POST",
    data,
  });
};
// 后台取消订单
export const CancelOrCustomerOrder = (data) => {
  return request({
    url: `${order}/CancelOrCustomerOrder`,
    method: "POST",
    data,
  });
};
// 保存订单
export const SaveOrder = (data) => {
  return request({
    url: `${order}/SaveOrder`,
    method: "POST",
    data,
  });
};
// 订单发货
export const SendEMS = (data) => {
  return request({
    url: `${order}/SendEMS`,
    method: "POST",
    data,
  });
};
// 订单支付记录退款
export const RefundPayLog = (data) => {
  return request({
    url: `${order}/RefundPayLog`,
    method: "POST",
    data,
  });
};
// 部分退货
export const SectionReturn = (data) => {
  return request({
    url: `${order}/SectionReturn`,
    method: "POST",
    data,
  });
};
// 导出商品发货单
export const ExportProductExcel = (data) => {
  return request({
    url: `${order}/ExportProductExcel`,
    method: "POST",
    responseType: "arraybuffer",
    data,
  });
};
// 待发货导出
export const ExportExcel = (data) => {
  return request({
    url: `${order}/ExportExcel`,
    method: "POST",
    responseType: "arraybuffer",
    data,
  });
};
// 导出地址
export const ExportAddressExcel = (data) => {
  return request({
    url: `${order}/ExportAddressExcel`,
    method: "POST",
    responseType: "arraybuffer",
    data,
  });
};
// 导出地址
export const ExportOnlyProductExcel = (data) => {
  return request({
    url: `${order}/ExportOnlyProductExcel`,
    method: "POST",
    responseType: "arraybuffer",
    data,
  });
};
// 查询待发货商品列表
export const GetWaitShipOrderProductList = (data) => {
  return request({
    url: `${order}/GetWaitShipOrderProductList`,
    method: "GET",
    data,
  });
};
// 导出待发货商品列表
export const ExportWaitShipProductExcel = (data) => {
  return request({
    url: `${order}/ExportWaitShipProductExcel`,
    method: "POST",
    responseType: "arraybuffer",
    data,
  });
};
// 获取待发货订单列表
export const GetWaitShipOrderListByProduct = (data) => {
  return request({
    url: `${order}/GetWaitShipOrderListByProduct`,
    method: "GET",
    data,
  });
};
// 导出待发货商品列表
export const ExportWaitShipByProduct = (data) => {
  return request({
    url: `${order}/ExportWaitShipByProduct`,
    method: "POST",
    responseType: "arraybuffer",
    data,
  });
};
// 获取已发货商品列表
export const GetShippedOrderProductList = (data) => {
  return request({
    url: `${order}/GetShippingOrderProductList`,
    method: "GET",
    data,
  });
};
// 导出已发货商品列表
export const ExportShippedProductExcel = (data) => {
  return request({
    url: `${order}/ExportShippedProductExcel`,
    method: "POST",
    responseType: "arraybuffer",
    data,
  });
};
// 获取已发货商品订单列表
export const GetProductOrderListByProduct = (data) => {
  return request({
    url: `${order}/GetProductOrderListByProduct`,
    method: "GET",
    data,
  });
};
// 导出已发货商品订单列表
export const ExportProductOrderByProduct = (data) => {
  return request({
    url: `${order}/ExportProductOrderByProduct`,
    method: "POST",
    responseType: "arraybuffer",
    data,
  });
};
// 获取已退货商品列表
export const GetReturnedOrderProductList = (data) => {
  return request({
    url: `${order}/GetReturnedOrderProductList`,
    method: "GET",
    data,
  });
};
// 导出已退货商品列表
export const ExportReturnedProductExcel = (data) => {
  return request({
    url: `${order}/ExportReturnedProductExcel`,
    method: "POST",
    responseType: "arraybuffer",
    data,
  });
};
// 获取已退货商品订单列表
export const GetReturnedProductOrderListByProduct = (data) => {
  return request({
    url: `${order}/GetReturnedProductOrderListByProduct`,
    method: "GET",
    data,
  });
};
// 导出已退货商品订单列表
export const ExportReturnedProductOrderByProduct = (data) => {
  return request({
    url: `${order}/ExportReturnedProductOrderByProduct`,
    method: "POST",
    responseType: "arraybuffer",
    data,
  });
};
// 导出收货地址列表
export const ExportAddressExcelForIDs = (data) => {
  return request({
    url: `${order}/ExportAddressExcelForIDs`,
    method: "POST",
    responseType: "arraybuffer",
    data,
  });
};
// 获取支付记录列表
export const GetPayLogList = (data) => {
  return request({
    url: `${order}/GetPayLogList`,
    method: "GET",
    data,
  });
};
// 修改支付记录状态
export const SavePayLogStatus = (data) => {
  return request({
    url: `${order}/SavePayLogStatus`,
    method: "POST",
    data,
  });
};
// 批量生成电子面单并发货
export const BatchDelivery = (data) => {
  return request({
    url: `${order}/BatchDelivery`,
    method: "POST",
    data,
  });
};

// 批量发货
export const BatchShipping = (data) => {
  return request({
    url: `${order}/BatchShipping`,
    method: "POST",
    data,
  });
};

export const GetBatchPrintInfo = (data) => {
  return request({
    url: `${order}/GetBatchPrintInfo`,
    method: "POST",
    data,
  });
};
export const cancelOrCustomerOrder = (id) =>
  request({
    url: `${order}/CancelOrCustomerOrder`,
    method: "POST",
    data: {
      orderID: id,
    },
  });

export const batchCancelDelivery = (data) =>
  request({
    url: `${order}/BatchCancelDelivery`,
    method: "POST",
    data,
  });

import store from "@/store";
import api from "@/api";
import { HOST, CONFIG } from "@/constant";

export const forEach = (arr, fn) => {
  if (!arr.length || !fn) return;
  let i = -1;
  let len = arr.length;
  while (++i < len) {
    let item = arr[i];
    fn(item, i, arr);
  }
};

export const showTitle = (item) => {
  let { title } = item.meta;
  if (!title) return;
  return (item.meta && item.meta.title) || item.name;
};

/**
 * @param {Array} arr1
 * @param {Array} arr2
 * @description 得到两个数组的并集, 两个数组的元素为数值或字符串
 */
export const getUnion = (arr1, arr2) => {
  return Array.from(new Set([...arr1, ...arr2]));
};

export const findNodeUpperByClasses = (ele, classes) => {
  let parentNode = ele.parentNode;
  if (parentNode) {
    let classList = parentNode.classList;
    if (
      classList &&
      classes.every((className) => classList.contains(className))
    ) {
      return parentNode;
    } else {
      return findNodeUpperByClasses(parentNode, classes);
    }
  }
};

/**
 * @param {Array} target 目标数组
 * @param {Array} arr 需要查询的数组
 * @description 判断要查询的数组是否至少有一个元素包含在目标数组中
 */
export const hasOneOf = (targetarr, arr) => {
  return targetarr.some((_) => arr.indexOf(_) > -1);
};

export const hasChild = (item) => {
  return item.children && item.children.length !== 0;
};

const showThisMenuEle = (item, access) => {
  if (item.meta && item.meta.access && item.meta.access.length) {
    if (hasOneOf(item.meta.access, access)) return true;
    else return false;
  } else return true;
};

export const getMenuByRouter = (list, access) => {
  let res = [];
  list.forEach((item) => {
    if (!item.meta || (item.meta && !item.meta.hideInMenu)) {
      let obj = {
        icon: (item.meta && item.meta.icon) || "",
        name: item.name,
        meta: item.meta,
      };
      if (
        (hasChild(item) || (item.meta && item.meta.showAlways)) &&
        showThisMenuEle(item, access)
      ) {
        obj.children = getMenuByRouter(item.children, access);
      }
      if (item.meta && item.meta.href) obj.href = item.meta.href;
      if (showThisMenuEle(item, access)) res.push(obj);
    }
  });

  return res;
};

/**
 * @param {*} obj1 对象
 * @param {*} obj2 对象
 * @description 判断两个对象是否相等，这两个对象的值只能是数字或字符串
 */
export const objEqual = (obj1, obj2) => {
  const keysArr1 = Object.keys(obj1);
  const keysArr2 = Object.keys(obj2);
  if (keysArr1.length !== keysArr2.length) return false;
  else if (keysArr1.length === 0 && keysArr2.length === 0) return true;
  /* eslint-disable-next-line */ else {
    return !keysArr1.some((key) => obj1[key] != obj2[key]);
  }
};

/**
 * @description 根据name/params/query判断两个路由对象是否相等
 * @param {*} route1 路由对象
 * @param {*} route2 路由对象
 */
export const routeEqual = (route1, route2) => {
  const params1 = route1.params || {};
  const params2 = route2.params || {};
  const query1 = route1.query || {};
  const query2 = route2.query || {};
  return (
    route1.name === route2.name &&
    objEqual(params1, params2) &&
    objEqual(query1, query2)
  );
};

/**
 * @param {*} list 现有标签导航列表
 * @param {*} newRoute 新添加的路由原信息对象
 * @description 如果该newRoute已经存在则不再添加
 */
export const getNewTagList = (list, newRoute) => {
  const { name, path, meta } = newRoute;
  let newList = [...list];
  if (newList.findIndex((item) => item.name === name) >= 0) return newList;
  else newList.push({ name, path, meta });
  return newList;
};

/**
 * @description 根据当前跳转的路由设置显示在浏览器标签的title
 * @param {Object} routeItem 路由对象
 * @param {Object} vm Vue实例
 */
export const setTitle = (routeItem, vm) => {
  const handledRoute = getRouteTitleHandled(routeItem);
  const pageTitle = showTitle(handledRoute, vm);
  const resTitle = pageTitle
    ? `${pageTitle} - ${process.env.VUE_APP_TITLE}`
    : `${process.env.VUE_APP_TITLE}`;
  window.document.title = resTitle;
};

export const getRouteTitleHandled = (route) => {
  let router = { ...route };
  let meta = { ...route.meta };
  let title = "";
  if (meta.title) {
    if (typeof meta.title === "function") {
      meta.__titleIsFunction__ = true;
      title = meta.title(router);
    } else title = meta.title;
  }
  meta.title = title;
  router.meta = meta;
  return router;
};

export const delayFunc = (delayTime = 800) =>
  new Promise((resolve) => setTimeout(resolve, delayTime));

export async function getCacheUpdatedTime(key, ts, func) {
  try {
    const res = await api.GetCacheUpdatedTime({ key: key });
    if (res.Status === 100) {
      if (ts) ts.cacheUpdatedTime = res.Data;
      if (func && typeof func === "function") func(res.Data);
    }
  } catch (error) {
    console.error(error);
  }
}

export const getAddressCodeToString = ({
  Province = 0,
  City = 0,
  District = 0,
  step = "",
}) => {
  let province;
  let district;
  let city;
  if (!store.state.app.addressJson) return false;
  province = store.state.app.addressJson.find(
    (item) => item.areaId === Province.toString()
  );
  if (province && province.cities.length !== 0) {
    city = province.cities.find((item) => item.areaId === City.toString());
  }
  if (city && city.counties.length !== 0) {
    district = city.counties.find(
      (item) => item.areaId === District.toString()
    );
  }
  return [
    province ? province.areaName : "",
    city ? city.areaName : "",
    district ? district.areaName : "",
  ].join(step);
};

export function importCache(key, ts, func, showModal = true) {
  const _this = this;
  if (!showModal) {
    api.ImportRedis({ key: key });
    return;
  }
  _this.$Modal.confirm({
    title: "提示",
    content: "您确定要更新缓存吗？",
    loading: true,
    onOk: async function () {
      try {
        const res = await api.ImportRedis({ key: key });
        if (res.Status === 100) {
          _this.$Message.success("更新缓存成功");
          _this.$Modal.remove();
          if (ts) ts.cacheUpdatedTime = res.Data;
          if (func && typeof func === "function") func();
        } else {
          _this.$Modal.remove();
        }
      } catch (error) {
        _this.$Modal.remove();
      }
    },
    onCancel: () => {
      _this.$Modal.remove();
    },
  });
}

export const getTableHeight = (parentNode, diff = 21) => {
  const main = document.querySelector(`.${parentNode}`);
  const mainH = main.offsetHeight;
  let h = 0;
  forEach(main.children, (item) => {
    if (!item.classList.contains("main-table")) {
      h += item.offsetHeight;
    }
  });
  return mainH - h - diff;
};

/**
 * @param {*} access 用户权限数组，如 ['super_admin', 'admin']
 * @param {*} route 路由列表
 */
const hasAccess = (access, route) => {
  if (route.meta && route.meta.access) {
    return hasOneOf(access, route.meta.access);
  } else return true;
};

/**
 * 权鉴
 * @param {*} name 即将跳转的路由name
 * @param {*} access 用户权限数组
 * @param {*} routes 路由列表
 * @description 用户是否可跳转到该页
 */
export const canTurnTo = (name, access, routes) => {
  const routePermissionJudge = (list) => {
    return list.some((item) => {
      if (item.children && item.children.length) {
        return routePermissionJudge(item.children);
      } else if (item.name === name) {
        return hasAccess(access, item);
      }
    });
  };

  return routePermissionJudge(routes);
};

export function handleHiddenSearch(el) {
  const formDom = el.querySelector(".search-form-container__content");
  const buttons = el.querySelectorAll(".search-form-container__button");
  const arrowBtn = el.querySelector(".search-form-container__arrow_btn");
  buttons.forEach((button) => {
    button.classList.toggle("hidden");
  });
  formDom.classList.toggle("hidden");
  if (formDom.classList.contains("hidden")) {
    arrowBtn.innerHTML = '展开<i class="ivu-icon ivu-icon-ios-arrow-down"></i>';
  } else {
    arrowBtn.innerHTML = '收起<i class="ivu-icon ivu-icon-ios-arrow-up"></i>';
  }
}

export function setImagePrefix(value) {
  if (value) {
    if (value.startsWith("http")) {
      return value;
    } else {
      let prefix = HOST.IMAGE_SERVER;
      if (!value.includes("ssdms_")) prefix = HOST.IMAGE_SERVER_OLD;
      return `${prefix}${value}`;
    }
  } else {
    return require("../assets/images/no_pic.png");
  }
}

export function delayLoad(func) {
  setTimeout(func, CONFIG.LOAD_DELAY_TIME);
}

import api from "@/api";
import types from "@/store/mutation-types";

export default {
  onLogin: async ({ commit }, payload) => {
    try {
      const res = await api.Login(payload);
      commit(types.SET_USER_INFO, res.Data);
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default {
  SET_USER_INFO: "SET_USER_INFO",
  SET_HOME_ROUTE: "SET_HOME_ROUTE",
  SET_BREAD_CRUMB: "SET_BREAD_CRUMB",
  SET_TAG_NAV_LIST: "SET_TAG_NAV_LIST",
  ClOSE_TAG: "CLOSE_TAG",
  ADD_TAG: "ADD_TAG",
  SET_INDEX_DATA: "SET_INDEX_DATA",
  SET_COUNT: "SET_COUNT",
  SET_LAST_LOGIN_LOG: "SET_LAST_LOGIN_LOG",
  SET_ADRESS_LIST: "SET_ADRESS_LIST",
  SET_QIUNIU_TOKEN: "SET_QIUNIU_TOKEN",
  SET_PRODUCT_BRAND: "SET_PRODUCT_BRAND",
  SET_PRODUCT_CLASS: "SET_PRODUCT_CLASS",
};
